import React from 'react'
import PropTypes from 'prop-types'
import './header.css'

const Header = ({ onAboutClick }) => {
  return (
    <div className="header-container">
      <div className="header-left">
        <a href="https://mipnet.de" target="new" className="header-mipnet-container">
          <img src="../mipnet-logo.png" alt="Logo" className="header-mipnet-logo" />
          <div className="header-mipnet-text">MiPnet</div>
        </a>
      </div>
      <div onClick={onAboutClick} className="header-right">
        Impressum
      </div>
    </div>
  )
}

Header.propTypes = {
  onAboutClick: PropTypes.func,
}

Header.defaultProps = {
  onAboutClick: () => { },
}

export default Header
