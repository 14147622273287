import React, { useEffect, useState } from 'react'
import { animated, useTransition } from 'react-spring'
import Post from './post'

import './animated-post.css'

const AnimatedPost = ({ content }) => {
  const [myContent, setMyContent] = useState('')
  const [screenWidth, setScreenWidth] = useState(1000)

  useEffect(() => {
    setMyContent(content)
    // eslint-disable-next-line no-undef
    setScreenWidth(window.innerWidth)
  }, [setMyContent, content])

  let postWidth = screenWidth < 1000 ? 638.4 : 715.2
  if (screenWidth < postWidth) {
    postWidth = screenWidth
  }

  const leftFrom = 0
  const leftEnter = screenWidth / 2 - postWidth / 2
  const leftLeave = screenWidth - postWidth

  const transitions = useTransition(myContent, null, {
    from: {
      position: 'absolute', opacity: 0, top: '0', left: `${leftFrom}px`,
    },
    enter: { opacity: 1, left: `${leftEnter}px` },
    leave: { opacity: 0, left: `${leftLeave}px` },
  })

  return (
    <div className="animated-post">
      {
            transitions.map(({ item, key, props }) => item
            && (
            <animated.div key={key} style={props}>
              <Post content={item} />
            </animated.div>
            ))
        }
    </div>
  )
}

export default AnimatedPost
