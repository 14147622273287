import React, { useState } from 'react'
import AnimatedPost from './animated-post'
import ChooserBar from './chooser-bar'
import Introduction from './introduction'
import Header from './header'
import Footer from './footer'
import './content.css'

const Content = ({ sites }) => {
  const countPosts = sites.posts.length
  const [activePost, setActivePost] = useState(0)
  const [postContent, setPostContent] = useState(sites.posts[0].html)

  function showPost(selectedPost) {
    setActivePost(selectedPost)
    setPostContent(sites.posts[selectedPost].html)
  }

  function showAbout() {
    setActivePost(-1)
    setPostContent(sites.pages.about.html)
  }

  return (
    <div id="content">
      <Header onAboutClick={showAbout} />
      <Introduction />
      <ChooserBar
        count={countPosts}
        activated={activePost}
        onSelect={selectedPost => showPost(selectedPost)}
      />
      <AnimatedPost content={postContent} />
      <Footer />
    </div>
  )
}

export default Content
