import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './chooser.css'

const Chooser = ({ id, activated, onClick }) => {
  function handleOnClick() {
    onClick(id)
  }

  return (
    <div className={classNames('chooser', { activated })} onClick={handleOnClick}>
      <div className={classNames('text', { activated })} onClick={handleOnClick}>
        {id + 1}
      </div>
    </div>
  )
}

Chooser.propTypes = {
  id: PropTypes.number.isRequired,
  activated: PropTypes.bool,
  onClick: PropTypes.func,
}

Chooser.defaultProps = {
  activated: false,
  onClick: () => {},
}

export default Chooser
