import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Content from './content'

const ContentData = () => {
  /*
    https://www.gatsbyjs.org/blog/2019-02-20-introducing-use-static-query/#known-limitations

    Because of the way queries are currently parsed in Gatsby, useStaticQuery has one small limitation at the moment. You can only use one instance of useStaticQuery in a file.
    */
  const queryResult = useStaticQuery(graphql`
        query MyQuery {
            allFile(filter: {extension: {eq: "md"}}) {
                group(field: dir) {
                    edges {
                        node {
                            childMarkdownRemark {
                                html
                            }
                        }
                    }
                }
            }
        }
    `)

  const sites = {
    posts: [],
    pages: {
      about: '',
    },
  }

  sites.posts = queryResult.allFile.group[1].edges.map(element => {
    return element.node.childMarkdownRemark
  })
  sites.pages.about = queryResult.allFile.group[0].edges[0].node.childMarkdownRemark

  return (
    <Content sites={sites} />
  )
}

export default ContentData
