import React from 'react'
import { Helmet } from 'react-helmet'
import ContentData from './content-data'

const Application = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Adventskalender für die Freundin</title>
        <link rel="canonical" href="https://adventskalender.mipnet.de" />
        <meta name="Description" content="Sammlung an Ideen für einen selbstgemachten Adventskalender" />
        <meta name="keywords" content="Adventskalender, Weihnachtskalender, selbstgemacht, Advent, Weihnachten, Ideen, Freundin" />
      </Helmet>
      <ContentData />
    </>
  )
}

export default Application
