import React from 'react'
import './introduction.css'

const Introduction = () => {
  return (
    <div id="introduction-container">
      <div id="introduction">
        Seit einigen Jahren schenke ich meiner Angebeteten für den Dezember einen Adventskalender. Damit andere, welche vielleicht auf der Suche nach Ideen sind, diese finden, möchte ich sie hier kurz festhalten.
      </div>
    </div>
  )
}

export default Introduction
