import React from 'react'
import PropTypes from 'prop-types'
import './post.css'

const Post = ({ content }) => {
  return (
    // eslint-disable-next-line react/no-danger
    <div className="post" dangerouslySetInnerHTML={{ __html: content }} />
  )
}

Post.propTypes = {
  content: PropTypes.string.isRequired,
}

export default Post
