import React from 'react'
import PropTypes from 'prop-types'
import Chooser from './chooser'
import './chooser-bar.css'

const ChooserBar = ({ count, activated, onSelect }) => {
  const choosers = []

  for (let i = 0; i < count; i++) {
    choosers.push(<Chooser id={i} activated={(i === activated)} key={i} onClick={onSelect} />)
  }

  return (
    <div id="chooser-bar">
      {choosers}
    </div>
  )
}

ChooserBar.propTypes = {
  count: PropTypes.number.isRequired,
  activated: PropTypes.number,
  onSelect: PropTypes.func,
}

ChooserBar.defaultProps = {
  activated: -1,
  onSelect: () => {},
}

export default ChooserBar
