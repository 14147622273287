import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-left">
        This page does not save or use your personal data in any form.
      </div>
      <div className="footer-right">
        proudly made without cookies
      </div>
    </div>
  )
}

export default Footer
